$themColor1: #3e94bf;
$themColor2: #3c509a;
$themColor3: #2a9c8f;
$themColorDark: #1c1c1d;
$themeBg: #1c1d26;
$themeRed: #ef4545;
$themeBlue: #408bff;

@mixin cardStyle {
  background-color: rgba(79, 83, 116, 0.9);
  border-radius: 10px;
  padding: 15px;

  h3 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
  }
}

@mixin xs {
  @media (max-width: 374px) {
    @content;
  }
}
@mixin sm {
  @media (max-width: 550px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media (max-width: 1000px) {
    @content;
  }
}
