@import '../../variable';
.sc-content {
  padding: 20px 0;
  grid-column: 1 / 3;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include md {
    grid-column: 1 / 1;
  }

  @include sm {
    padding: 15px 0 0;
  }

  .cont {
    width: 50%;
    padding: 15px;

    @include md {
      width: 100%;
      padding: 0 10px;
    }
  }

  h3 {
    margin: 20px 0 10px;
    font-size: 20px;
  }

  a {
    text-decoration: underline;
    color: #eee;
    font-weight: 600 !important;
    font-size: 16px !important;
    opacity: 1 !important;
  }

  .table-scroll-wrap {
    margin-bottom: 10px;
    table {
      th,
      td {
        font-size: 14px;
      }

      th {
      }
    }
  }
  article {
    + article {
      margin-top: 25px;
    }

    * {
      opacity: 0.75;
      font-size: 14px;
      line-height: 1.6;
    }

    h3 {
      opacity: 1;
      font-size: 16px;
      margin-bottom: 5px;
    }

    ul {
      margin: 10px 0 15px;
      li {
        position: relative;
        padding-left: 10px;
        text-indent: -10px;
        opacity: 1;

        + li {
          margin-top: 8px;
        }
        &::before {
          content: '-';
          margin-right: 5px;
        }
      }
    }
  }
}
